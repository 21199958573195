import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppContainer from './components/AppContainer';
import ErrorPage from './components/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/', element: <AppContainer />, errorElement: <ErrorPage />
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
