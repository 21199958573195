import React, { useState } from "react";
import Header from "./Header";
import Sankalpapatra from "./Sankalpapatra";

const AppContainer = () => {
  const [language, setlanguage] = useState('english');

  return(
    <>
    <Header language={language} setlanguage={setlanguage} ></Header>
    <Sankalpapatra language={language} ></Sankalpapatra>
    </>
  );
}

export default AppContainer;