import React from "react";
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import BjpLogo from './../assets/bjp_logo_temp.png';
import BjpLogo from './../assets/lotus-dark.png';
// import BjpLogo from './../assets/bjp_logo_new.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const Sankalpapatra = ({ language }) => {

  return (
    <>

      <div style={{ height: '300px', width: '400px', position: 'fixed', top: '50%', left: '50%', backgroundImage: `url(${BjpLogo})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', opacity: '0.6', transform: 'translate(-50%, -50%)', zIndex: '-1' }}></div>

      <div style={{ width: 'auto', maxWidth: '1000px', padding: '10px 20px', display: 'flex', flexDirection: 'column', gap: '12px', margin: 'auto', }}>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: '0px solid #1976d2', borderRadius: '4px', backgroundColor: 'rgba( 255, 255, 255, 0.1)', }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            href="https://shwetapatra.in/"
            target="_blank">
            Shwetapatra
          </Button>
        </div>

        {language === 'english' ?
          <>
            <div class="garib" >
              <h2>Garib Parivar Jan</h2>
              <ul>
                <li>We guarantee to serve the needs of Garib Parivar Jan. We will provide free rations, gas connection and zero electricity bills through PM Surya Ghar for the next 5 years.</li>
              </ul>
            </div>

            <div class="middle-class">
              <h2>Middle Class Parivar Jan</h2>
              <ul>
                <li>We will work towards improving all aspects of middle class living.</li>
                <li>The middle-class dreams of owning their own home. We will take all steps towards fulfilling this. It is Modi’s Guarantee to provide high-quality houses at low costs.</li>
                <li>We will expand healthcare services, set up new educational institutions and build infrastructure to ease and improve the standard of living for middle class families.</li>
                <li>We will work towards clean air, pure water and a green environment in all villages and cities.</li>
              </ul>
            </div>

            <div class="young-citizens">
              <h2>Young Citizens</h2>
              <ul>
                <li>We have provided lakhs of youth with employment through transparent recruitment in Central Government jobs. We have brought in a strict law against paper leaks. We will implement it strictly. </li>
                <li>We will create employment opportunities for the youth by providing infrastructure, investment,  and promoting manufacturing, high value services, startups, tourism and sports. </li>
                <li>We will provide youth with self-employment opportunities through MUDRA, SVANidhi, and digital credit. </li>
              </ul>
            </div>

            <div class="nari-shakti">
              <h2>Nari Shakti</h2>
              <ul>
                <li>We have created one crore Lakhpati Didis. We will now empower three crore women to become Lakhpati Didis. We will create more opportunities for SHGs by integrating them with services sector. We will also integrate SHGs with initiatives like Farmer Producer Organisations, One District One Product, ONDC, and GeM.</li>
                <li>We will lay special focus on treating diseases like cervical cancer, breast cancer, and osteoporosis.</li>
                <li>We will build more public toilets for women.</li>
                <li>We will implement Nari Shakti Vandan Adhiniyam.</li>
              </ul>
            </div>

            <div class="senior-citizens">
              <h2>Senior Citizens</h2>
              <ul>
                <li>We will include Senior Citizens under the healthcare services of Ayushman Bharat.</li>
                <li>We will initiate programs to facilitate pilgrimages for the elderly.</li>
              </ul>
            </div>

            <div class="kisan-samman">
              <h2>Kisan Samman</h2>
              <ul>
                <li>We will augment income of farmers by creating a ‘beej- to- bazaar’ model. We will promote millets (Shree Anna) as superfood. We will preserve our soil through nano urea and natural farming.</li>
                <li>We will boost agri infrastructure through the Krishi Gati Shakti project.</li>
              </ul>
            </div>

            <div class="fishermen-families">
              <h2>Fishermen Families</h2>
              <ul>
                <li>We will focus on every aspect of fishermen welfare including insurance for fishing boats, setting up fish processing units, satellites for real time information and more.</li>
                <li>We will encourage fishermen to take up seaweed and pearl farming.</li>
              </ul>
            </div>

            <div class="shramik-samman">
              <h2>Shramik Samman</h2>
              <ul>
                <li>We will run special programs for gig workers, migrant workers, taxi drivers, truck drivers, auto drivers, porters, house helps and others through the e-shram portal.</li>
                <li>We will create dedicated facilities for truck drivers on highways.</li>
              </ul>
            </div>

            <div class="msme-traders">
              <h2>Vishwakarma, MSME and Small Traders</h2>
              <ul>
                <li>We will provide guarantee for the skill training, credit availability and marketing of products for MSMEs, Vishwakarma persons and small traders.</li>
              </ul>
            </div>

            <div class="prosperous-bharat">
              <h2>Prosperous Bharat</h2>
              <ul>
                <li>Bharat was stagnant at 11th position from 2004 to 2014. We made Bharat the 5th largest economy in ten years. We guarantee that Bharat will become the third largest economy.</li>
                <li>We will provide lakhs of employment and entrepreneurship opportunities through the manufacturing, infrastructure and services sector.</li>
              </ul>
            </div>


            <div class="world-class-infrastructure">
              <h2>World Class Infrastructure</h2>
              <ul>
                <li>We pledge Modi’s guarantee to create opportunities and facilities to generate employment and livelihoods.</li>
                <li>We guarantee to create new railway lines, Vande Bharat, Amrit Bharat and Namo Bharat trains, and world-class railway stations. We will phase out ‘waiting-list’ in the coming years.</li>
                <li>We will increase the number of airports, highways, metros, water metros, and other services. We will initiate the feasibility study of three new bullet train corridors in the North, South and East.</li>
              </ul>
            </div>

            <div class="vishwa-bandhu">
              <h2>Vishwa Bandhu</h2>
              <ul>
                <li>We will facilitate a special certification course in Yoga to increase its reach across the world.</li>
                <li>We will establish Thiruvalluvar Cultural Centers across the globe to showcase Bharat’s rich culture.</li>
                <li>We will encourage the study of classical Indian languages.</li>
              </ul>
            </div>

            <div class="sabka-saath">
              <h2>Sabka Saath Sabka Vikas</h2>
              <ul>
                <li>We guarantee the development of every marginalized group. We will declare 2025 as the Janjatiya Gaurav Varsh.</li>
                <li>We will increase Eklavya schools, and strengthen initiatives like PM JANMAN, reforms in minor forest produce and eco-tourism sector.</li>
                <li>We guarantee the dignity of SC, ST and OBC communities in every sphere of life.</li>
              </ul>
            </div>

            <div class="healthcare-education-sports">
              <h2>Healthcare, Education and Sports</h2>
              <ul>
                <li>We guarantee to provide a holistic life, good hospitals and affordable medicines.</li>
                <li>By implementing NEP, we guarantee high quality education to every citizen.</li>
                <li>We will bid to host the Olympics in 2036. We guarantee to provide opportunities to the youth of India to come forward in every sport.</li>
              </ul>
            </div>

            <div class="global-manufacturing-hub">
              <h2>Global Manufacturing Hub</h2>
              <ul>
                <li>We guarantee to make Bharat a Global Manufacturing Hub and further guarantee the creation of lakhs of jobs through this.</li>
                <li>It is Modi’s Guarantee to strengthen manufacturing in Steel, cement, aluminum,  railway, defence, aviation, pharmaceuticals, semiconductor, mobile, EV, lab diamond and other industries.</li>
              </ul>
            </div>

            <div class="balanced-regional-development">
              <h2>Balanced Regional Development</h2>
              <ul>
                <li> We guarantee the development of every region in Bharat. We guarantee to make Master Plans catering to the unique needs of the North East, Island groups, mountain regions and desert.</li>
              </ul>
            </div>

            <div class="technology-and-innovations">
              <h2>Technology and Innovations</h2>
              <ul>
                <li>  We have created a global identity for Digital Technology.  We guarantee to make Bharat the leader in Space, AI, Quantum, Semiconductor, Renewable, EV, and other technologies.</li>
              </ul>
            </div>

            <div class="sustainable-bharat">
              <h2>Sustainable Bharat</h2>
              <ul>
                <li>We guarantee to provide pure water, clean air and nutritious meal for the health and wellness of every citizen. For this we guarantee to protect and preserve water, forests, air and the environment.</li>
              </ul>
            </div>

            <div class="ease-of-living">
              <h2>Ease of Living</h2>
              <ul>
                <li>We guarantee to improve ease of living in the cities by providing Urban Housing, transportation, water management, clean air, waste management and drinking water</li>
              </ul>
            </div>

            <div class="virasat-bhi">
              <h2>Virasat Bhi, Vikas Bhi</h2>
              <ul>
                <li>We guarantee the all-round development of Bhartiya heritage.</li>
                <li>We guarantee to develop Ayodhya Ji as a center of faith across the world. We will celebrate Ramayana Festival across the world.</li>
              </ul>
            </div>

            <div class="good-governance">
              <h2>Good Governance</h2>
              <ul>
                <li>We guarantee to implement our mantra of ‘Perform, Reform, Transform’ in every aspect of governance.</li>
                <li>We guarantee strict action against corruption.</li>
                <li>We will implement the Bharatiya Nyay Samhita.</li>
                <li>We will bring the One Nation One Election and common electoral roll facility.</li>
              </ul>
            </div>
          </>
          :
          <>
            <div class="garib">
              <h2>गरीब परिवार जन </h2>
              <ul>
                <li>हम गरीब परिवारजनों की सेवा करने की गारंटी देते हैं। अगले 5 वर्षों तक मुफ्त राशन, गैस कनेक्‍शन और PM सूर्य घर से जीरो बिजली बिल की व्‍यवस्‍था करेंगे। </li>
              </ul>
            </div>

            <div class="middle-class">
              <h2>मध्‍यम वर्ग परिवार जन</h2>
              <ul>
                <li>हम मध्‍यम वर्ग परिवारों के जीवन से जुड़े आयामों पर काम करेंगे। </li>
                <li>मध्‍यम वर्गीय परिवार का बड़ा सपना होता है अपना घर। हम इसके लिए बड़े प्रयास करेंगे। हर परिवार को कम कीमत पर अच्‍छा घर मिले, यह मोदी की गारंटी है।</li>
                <li>मध्‍यम वर्गीय परिवारजनों के लिए स्‍वास्‍थ्‍य सेवाओं का विस्‍तार, नए शिक्षण संस्‍थान और जीवन सुगम बनाने के लिए इंफ्रास्‍ट्रक्‍चर में सुधार करेंगे।</li>
                <li>हम शहरों और गांवों में साफ हवा, शुद्ध पानी और स्‍वच्‍छ पर्यावरण के लिए काम करेंगे। </li>
              </ul>
            </div>

            <div class="young-citizens">
              <h2>युवाओं  को अवसर</h2>
              <ul>
                <li>हमने केन्‍द्र सरकार में पारदर्शी परीक्षाओं से लाखों युवाओं को रोजगार दिया है। हमने पेपर लीक पर कड़ा कानून बनाया है। अब इस कानून को लागू करेंगे।</li>
                <li>युवाओं के लिए infrastructure, investment, manufacturing, high value services, startups, tourism और sports के माध्‍यम से लाखों रोजगार के अवसर पैदा करेंगे।</li>
                <li>युवाओं को Mudra, स्‍वनिधि, Digital Credit आदि से स्‍वरोजगार के नए अवसर देंगे।</li>
              </ul>
            </div>

            <div class="nari-shakti">
              <h2>नारी शक्ति </h2>
              <ul>
                <li>हमने 1 करोड़ लखपति दीदी बनाई हैं। अब हम 3 करोड़ लखपति दीदी के लक्ष्‍य पर काम करेंगे। महिला स्‍वयं सहायता समूह (SHG) को services sector से जोड़कर नए अवसर देंगे। Farmer Produce Organization, One District One Product, ONDC, GeM जैसे कार्यक्रमों से भी महिला SHG को जोड़ेंगे।</li>
                <li>हम महिलाओं के स्‍वास्‍थ्‍य से संबंधि‍त cervical cancer, breast cancer, osteoporosis इत्‍यादि समस्‍याओं पर विशेष ध्‍यान देंगे।</li>
                <li>हम महिलाओं के लिए सार्वजनिक स्‍थानों पर शौचालय बनाएंगे।</li>
                <li>नारी वंदन अधिनियम को लागू करेंगे।</li>
              </ul>
            </div>

            <div class="senior-citizens">
              <h2>वरिष्‍ठ नागरिक </h2>
              <ul>
                <li>हम वरिष्‍ठ नागरिकों के स्‍वस्‍थ जीवन के लिए उन्‍हें आयुष्‍मान भारत योजना में शामिल करेंगे । </li>
                <li>वरिष्‍ठ नागरिकों की तीर्थ यात्रा के लिए विशेष कार्यक्रम आरंभ करेंगे ।</li>
              </ul>
            </div>

            <div class="kisan-samman">
              <h2>किसान सम्मान </h2>
              <ul>
                <li>हम बीज से बाजार तक किसानों की आय बढ़ाने के प्रयास करेंगे । हम श्री अन्‍न को superfood की तरह स्‍थापित करेंगे। नैनो यूरिया और प्राकृतिक खेती से जमीन की सुरक्षा करेंगे ।</li>
                <li>हम कृषि गति शक्ति के रूप में कृषि Infrastructure का विशेष विकास करेंगे ।</li>
              </ul>
            </div>

            <div class="fishermen-families">
              <h2>मत्स्य पालक परिवारजनों की समृद्धि </h2>
              <ul>
                <li>मछुआरों के जीवन से जुड़ी हर बात जैसे नाव का बीमा, fish processing unit, satellite द्वारा समय पर जानकारी इत्‍यादि पर विशेष ध्‍यान देंगे ।</li>
                <li>हम मछली पालकों को sea-weed और मोती की खेती के लिए प्रोत्‍साहित करेंगे।</li>
              </ul>
            </div>

            <div class="shramik-samman">
              <h2>श्रमिक सम्मान </h2>
              <ul>
                <li>हम gig workers, migrant workers, taxi drivers, truck driver, auto driver, कुली, घरों में काम करने वाले श्रमिक, सभी के लिए e-shram के माध्‍यम से विशेष कार्यक्रम चलाएंगे।</li>
                <li>Highway पर ट्रक चालकों के लिए विशेष सुविधाएं बनवाऐंगे ।</li>
              </ul>
            </div>

            <div class="msme-traders">
              <h2>विश्वकर्मा परिवार जनों, MSME ओर छोटे traders</h2>
              <ul>
                <li>विश्वकर्मा परिवार जनों, MSME ओर छोटे traders को skill, credit और उनके उत्पादन को बाजार से जोड़ने की गारण्टी हम लेते हैं । </li>
              </ul>
            </div>

            <div class="prosperous-bharat">
              <h2>समृद्ध भारत</h2>
              <ul>
                <li>2004 से 2014 तक भारत 11वें स्थान पर रहा । हम दस वर्षों में भारत को 11वें स्थान से 5वें स्थान पर लाए । अब हम भारत को विश्व की तीसरी बड़ी आर्थि‍क शक्ति बनाने की गारण्टी देते हैं । </li>
                <li>हम भारत में manufacturing, infrastructure और services क्षेत्रों में लाखों रोजगार और स्वरोजगार के अवसर उपलब्‍ध कराएंगे।</li>
              </ul>
            </div>


            <div class="world-class-infrastructure">
              <h2>विश्वस्तरीय इंफ्रास्ट्रक्चर</h2>
              <ul>
                <li>हम नागरिकों की सुविधा और रोजगार के अवसर पैदा करने के लिए मोदी की गारण्टी देते हैं ।</li>
                <li>हम रेलवे में नई पटरियां , नई वन्दे भारत, अमृत भारत और नमो भारत ट्रेनों और विश्वस्तरीय station बनाने की गारण्टी देते हैं । आने वाले वर्षों में वेट लिस्ट की समस्या एकदम कम हो जाएगी ।</li>
                <li>हम नये airport, highway, metro, water metro, इत्यादि सुविधाओं को बढ़ाएंगे। तीन और बुलेट ट्रेन corridor की feasibility study आरंभ करेंगे । ये उत्तर, दक्षि‍ण, और पूरब में होंगे ।
                  ग्लोबल मैन्युफैक्चरिंग हब</li>
              </ul>
            </div>

            <div class="vishwa-bandhu">
              <h2>विश्‍व बंधु</h2>
              <ul>
                <li>भारत के योग को विश्‍व भर में प्रामणिकता से बढ़ाने के लिए हम एक Certification की व्‍यवस्‍था करेंगे।</li>
                <li>हम विश्‍व भर में थिरूवल्‍लूवर Cultural Center के माध्‍यम से भारत की संस्‍कृति को विश्‍व में ले जाएंगे।</li>
                <li>हम भारत की Classical भाषाओं के अघ्‍ययन की व्‍यवस्‍था उच्‍च शिक्षण संस्‍थाओं में करेंगे।</li>
              </ul>
            </div>

            <div class="sabka-saath">
              <h2>सबका साथ सबका विकास</h2>
              <ul>
                <li>हम हर वंचित वर्ग के विकास की गारंटी देते हैं। हम 2025 को जनजातीय गौरव वर्ष के  रूप में घोषित करेंगे।</li>
                <li>हम एकलव्‍य स्‍कूल, PM जनमन,वन उत्‍पादों में value addition और Eco tourism को बढ़ावा देंगे।</li>
                <li>हम OBC, SC एवं ST समुदायों को जीवन के हर क्षेत्र में सम्‍मान देंगे।</li>
              </ul>
            </div>

            <div class="healthcare-education-sports">
              <h2>स्‍वास्‍थ्‍य, शिक्षण, खेल</h2>
              <ul>
                <li>हम संतुलित जीवन, अच्‍छे Hospital और सस्‍ती दवाएं प्रदान करने की गारंटी देते हैं।</li>
                <li>हम NEP को लागू करके हर नागरि‍क को high quality शिक्षा की गारंटी देते हैं।</li>
                <li>हम 2036 में Olympics की मेजबानी करेंगे। हम हर खेल में भारत के युवाओं को आगे आने के अवसर देने की गारंटी देते हैं।</li>
              </ul>
            </div>

            <div class="global-manufacturing-hub">
              <h2>ग्लोबल मैन्युफैक्चरिंग हब</h2>
              <ul>
                <li>हम भारत को Global Manufacturing Hub बनाने की गारंटी देते हैं। और इससे लाखों रोजगार के अवसर पैदा करने की गारंटी देते हैं।</li>
                <li>Steel, cement, aluminum, रेलवे, defence, aviation, pharmaceuticals, semiconductor, mobile, EV, lab diamond इत्‍यादि अनेक क्षेत्रों में manufacturing बढ़ाने की मोदी की गारंटी है। </li>
              </ul>
            </div>


            <div class="balanced-regional-development">
              <h2>सभी क्षेत्रों के समग्र विकास </h2>
              <ul>
                <li>  हम भारत के हर क्षेत्र के विकास की गारंटी देते हैं। North East तटीय क्षेत्र, द्वीप समूह, पहाड़ी क्षेत्र, रेगिस्‍तान इत्‍यादि हर क्षेत्र की विशेष जरूरतों के अनुसार Master plan बनाने की गारंटी देते हैं।</li>
              </ul>
            </div>

            <div class="technology-and-innovations">
              <h2>तकनीक एवं नवाचार </h2>
              <ul>
                <li>हमने Digital Technology में विश्‍व में पहचान बनाई है। अब हम Space, AI, Quantum, Semicondutor, Renewable, EV, जैसी Technologies में भारत को अग्रणी बनाने की गारंटी देते हैं। </li>
              </ul>
            </div>

            <div class="sustainable-bharat">
              <h2>पर्यावरण अनुकूल भारत</h2>
              <ul>
                <li>हम हर नागरि‍क को शुद्ध पानी, साफ हवा और पोषक भोजन मिले, हर नागरिक योग, आयुष और Fitness से स्‍वस्‍थ रह सके, इसके लिए जल, जंगल, वायु और पर्यावरण को बचाने की गारंटी देते हैं।</li>
              </ul>
            </div>

            <div class="ease-of-living">
              <h2>ईज ऑफ लिविंग</h2>
              <ul>
                <li>हम शहरों में रहने वाले परिवारजनों के जीवन को आसान बनाने के लिए Urban Housing, transportation, water management, साफ हवा, कचरे के ढेर से मुक्ति और स्‍वच्‍छ पानी की गारंटी देते हैं।</li>
              </ul>
            </div>

            <div class="virasat-bhi">
              <h2>विरासत भी, विकास भी </h2>
              <ul>
                <li>विरासत के सर्वांगीण विकास की गारंटी देते हैं। </li>
                <li>अयोध्‍याजी को विश्‍वभर में center of faith के रूप में विकसित करने की गारंटी देते हैं। हम विश्‍वभर में रामायण उत्‍सव मनाएंगे।</li>
              </ul>
            </div>

            <div class="good-governance">
              <h2>सुशासन</h2>
              <ul>
                <li>हम Perform Reform Transform के अपने मंत्र को शासन के हर क्षेत्र में लागू करने की गारंटी देते हैं।</li>
                <li>भाजपा का मानना है कि जब तक भारत में समान नागरिक संहिता को अपनाया नहीं जाता, तब तक महिलाओं को समान अधिकार नहीं मिल सकता। भाजपा सर्वश्रेष्ठ परम्पराओं से प्रेरित समान नागरिक संहिता बनाने के लिए प्रतिबद्ध है जिसमें उन परम्पराओं को आधुनिक समय की जरूरतों के मुताबिक ढाला जाए।</li>
                <li>हम भ्रष्‍टाचार के खिलाफ सख्‍त कार्रवाई करने की गारंटी देते हैं।</li>
                <li>हम भारतीय न्‍याय संहिता को लागू करेंगे।</li>
                <li>हम one nation one election और common electoral roll की व्‍यवस्‍था लाएंगे।</li>
              </ul>
            </div>
          </>
        }

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #1976d2', borderRadius: '4px', backgroundColor: 'rgba( 255, 255, 255, 0.1)' }}>
          <h4>{language === 'english' ? 'Click here to view detailed Manifesto' : 'विस्तृत संकल्प पत्र देखने के लिए यहां क्लिक करें'}</h4>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', marginBottom: '1em' }}>
            <Button
              variant="outlined"
              style={{ fontWeight: 'bold' }}
              startIcon={<FileDownloadIcon />}
              href="https://www.bjp.org/files/inline-documents/Modi-Ki-Guarantee-Sankalp-Patra-English_0.pdf"
              target="_blank">
              English
            </Button>
            <Button
              variant="outlined"
              style={{ fontWeight: 'bold' }}
              startIcon={<FileDownloadIcon />}
              href="https://www.bjp.org/files/inline-documents/Modi-Ki-Guarantee-Sankalp-Patra-Hindi_0.pdf"
              target="_blank">
              Hindi
            </Button>
          </div>
        </div>

      </div>
    </>
  );
}

export default Sankalpapatra;