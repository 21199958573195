import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './../App.css';
import LanguageIcon from '@mui/icons-material/Language';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const Header = ( {language, setlanguage} ) => {

  const changeLanguage = (newLanguage) => {
    setlanguage(newLanguage)
  };

  return (
    <AppBar className='header' position="sticky" style={{ top: 0, color: 'black', zIndex: 1000 }}>
      <Toolbar>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#4381c3', fontFamily: "'Itim', sans-serif", cursor: 'pointer',  }}>
            { language === 'english' ? 'Sankalpapatra' : 'संकल्पपत्र' }
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ margin: 0 }}>
            <Select
              value={language}
              onChange={(e) => changeLanguage(e.target.value)}
              sx={{ padding: 0 }}
              IconComponent={() => <LanguageIcon color="primary" />}
            >
              <MenuItem value="english">English</MenuItem>
              <MenuItem value="hindi">हिंदी</MenuItem>
            </Select>
          </FormControl>

        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;